import { ChangeDetectorRef, Component } from '@angular/core';
import { BulkOrderService } from '../../services/bulk-order-service';
import { Observable } from 'rxjs';
import { Column, ColumnType } from '../../../../SGRE-shared/models/tableConfiguration';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';

@Component({
  selector: 'app-view-tabe-not-found',
  templateUrl: './view-tabe-not-found.component.html',
  styleUrl: './view-tabe-not-found.component.scss'
})
export class ViewTabeNotFoundComponent {

  public columns: Column[];
  public columnType: any[];
  public cartsList: any[] = [];
  currentpage$: Observable<number>
  cols: cols[] = [];
  schema: AlbumSchema[] = [];
  title = 'primetable';
  bulkDetails$: Observable<any>;
  productcode: string;
  productdesc: string;
  albums: any[] = [];
  public userRoles: any[];
  role: string;

  constructor(
    public bulkservice: BulkOrderService,
    public changeRef: ChangeDetectorRef,
    public storageService: StorageService
  ) { }

  ngOnInit() {
    this.getData()
    this.currentpage$ = this.bulkservice.isNext$;
    this.createTableConfiguration();
    this.cartsList = this.albums;
    this.getUserRole();
  }

  getUserRole() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;
    if (userRole?.includes("Requester") && !userRole?.includes("Purchaser")) {
      this.role = 'Requester';
    }
    else if (userRole?.includes("Purchaser") && !userRole?.includes("Requester")) {
      this.role = 'Purchaser';
    }
    else if (userRole?.includes("Purchaser") && userRole?.includes("Requester")) {
      this.role = 'Purchaser&Requester'
    }
  }

  createTableConfiguration() {
    let columnData = [
      { label: "Part Number", name: "partNumber", type: ColumnType.link },
      { label: "Quantity ", name: "quantity", type: ColumnType.number },
    ];
    this.columns = columnData.map(item => new Column(item));
  };

  getData() {
    this.bulkservice.tableDataSubject.subscribe((req) => {
      const data = req;
      if (Object.keys(data).length > 0) {
        // 1. Remove duplicates from notFoundProductCodes using Set
        const notFoundProductCodes = new Set(data.notFoundProductCodes?.length >= 25 ? data.notFoundProductCodes.slice(0,25):data.notFoundProductCodes);
        // 2. Create cartsList with unique entries based on partNumber
        this.cartsList = [];
        for (const code of notFoundProductCodes) {
          let quantity = 0;
          for (const row of this.bulkservice.excelDataSubject.getValue()) {
            if (code === row.partNumber) {
              quantity = row.quantity;
              break;
            }
          }
          this.cartsList.push({
            partNumber: code,
            quantity: quantity
          });
        }
        this.changeRef.detectChanges();
      }
    });
  }
}
export interface Element {
  username: string;
  name: string;
  Company: number;
  legal_entities: string;
}
interface AlbumSchema {
  field: string;
  header: string;
}
export interface cols {
  field: string;
  header: string;
}